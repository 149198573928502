import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';

import {
  provideHttpClient,
  withInterceptors,
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  withFetch,
} from '@angular/common/http';
import { tokenInterceptor } from './interceptors/token.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideAnimations } from '@angular/platform-browser/animations';

import { provideToastr } from 'ngx-toastr';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

import {
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';

// provideHttpClient(
//   withInterceptors([tokenInterceptor]),
//   withInterceptorsFromDi(),
// ),

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([tokenInterceptor,]),
      withInterceptorsFromDi(),
      withFetch()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    provideAnimationsAsync('animations'),
    provideAnimations(),
    provideToastr({
      newestOnTop: true,
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing',
      timeOut: 4000,
      maxOpened: 1,
    }),
    provideEnvironmentNgxMask(),
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { locale: 'pt-br' } },
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      })
    ),

  ],
};
