import { Pipe, PipeTransform } from '@angular/core';
import { IUserLoginData } from '../models/WS.data.model';

@Pipe({
  name: 'conectados',
  standalone: true,
})
export class ConectadosPipe implements PipeTransform {
  transform(
    list: IUserLoginData[],
    opcao: 'todos' | 'usuarios' | 'clientes',
    filtroEmpresa: number,
  ): IUserLoginData[] {

    if (opcao === 'todos') 
      return list.filter((value) => (filtroEmpresa !== 0 ? value.empresa_logada === filtroEmpresa : true));

    if (opcao === 'usuarios') 
      return list.filter((value) => value.user_role !== 'CUSTOMER' && (filtroEmpresa !== 0 ? value.empresa_logada === filtroEmpresa : true));

    if (opcao === 'clientes')
      return list.filter((value) => value.user_role === 'CUSTOMER' && (filtroEmpresa !== 0 ? value.empresa_logada === filtroEmpresa : true));

    return list;
  }
}
