import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LoadingService } from '../services/loading.service';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';

let pendingRequests = 0;

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private readonly loadingService: LoadingService) {
    // console.log('interceptor...');
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // debugger;

    const _url = req.url;

    if (
      !(
        _url.includes(
          environment.API_ACCOUNT.Methods.GET_EMPRESAS_AGUARDANDO
        ) ||
        _url.includes(
          environment.API_ACCOUNT.Methods.GET_USUARIOS_AGUARDANDO
        ) ||
        _url.includes(
          environment.API_ACCOUNT.Methods.GET_REQUISICAO_RESGATE_AGUARDANDO
        ) ||
        _url.includes(
          environment.API_ACCOUNT.Methods.GET_REQUISICAO_RESGATE_APROVADA
        ) ||
        _url.includes(
          environment.API_ACCOUNT.Methods.GET_REQUISICAO_ID_RESGATE_AGUARDANDO
        )
      )
    )
      this.loadingService.showLoading();

    pendingRequests = pendingRequests + 1;

    return next.handle(req).pipe(
      tap({
        next: (event) => {
          if (event.type === HttpEventType.Response) {
            this.handleHideLoading();
          }
        },
        error: (err) => {
          this.handleHideLoading();
        },
      })
    );
  }

  handleHideLoading() {
    pendingRequests = pendingRequests - 1;

    if (pendingRequests == 0) {
      this.loadingService.hideLoading();
    }
  }
}
