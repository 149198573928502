import { Pipe, PipeTransform } from '@angular/core';
import { SpecialDataService } from '../services/special-data.service';

@Pipe({
  name: 'pageAlias',
  standalone: true
})
export class PageAliasPipe implements PipeTransform {

  constructor(private readonly specialData: SpecialDataService) {}

  transform(value: any): string|null {

    const _alias = this.specialData.rotasAlias();
    let _value = value;

    if(!value) return null;

    if(_alias) {
      _value = _alias[value];
      if(!_value) {
        _value = value;
      }
    }
    return _value;
  }

}
