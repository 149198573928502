import {
  HttpContextToken,
  HttpEvent,
  HttpEventType,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { accessTokenDataModel } from '../models/Token.data.model';
import { inject, InjectionToken, PLATFORM_ID, signal } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { IApiResponse } from '../interfaces/api-response.interface';
import { LocalStorageService } from '../services/local-storage.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Observable, tap } from 'rxjs';

// export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
//   let hasToken = false;

//   const authService = inject(AuthenticationService);
//   const storageService = inject(LocalStorageService);
//   const platform = inject(PLATFORM_ID);

//   console.log(
//     'tokenInterceptor: onde estou: [client | server ]',
//     isPlatformBrowser(platform),
//     isPlatformServer(platform)
//   );

//   const currentToken = () =>
//     new Promise<accessTokenDataModel | undefined>((resolve, reject) => {
//       console.log('[ interceptor ] - request:', req);

//       const _tokenJson = storageService.getSessionItem(
//         environment.LOCAL_STORAGE_TOKEN_KEY
//       );
//       console.log('[ interceptor ] - current token:', _tokenJson);

//       if (_tokenJson) {
//         const _token = JSON.parse(_tokenJson) as accessTokenDataModel;
//         resolve(_token);
//       }
//       reject('sem token');
//     });

//   const checkToken = () =>
//     new Promise<boolean>(async (resolve, reject) => {
//       console.log('[ interceptor ] - checking token');

//       let _token = await currentToken().catch((error) => {
//         console.log('[ interceptor ] - checkToken error:', error);
//         reject('no_token');
//       });
//       console.log('[ interceptor ] - verificar validade token:', _token);

//       if (_token && _token.accessToken && _token.dataFimValidade) {
//         const isVencido = authService.checkVencimentoToken(_token);
//         console.log('[ interceptor ] - token vencido?', isVencido);

//         if (isVencido) {
//           const _result = await refreshToken().catch((error) => {
//             console.log('[ interceptor ] - refresh token -> ERRO:', error);

//             authService.setTokenVencido(isVencido);

//             reject('token_vencido');
//             // return;
//           });

//           console.log('[ interceptor ] - refresh token result:', _result);

//           if (!_result) authService.setTokenVencido(false);
//         }

//         authService.setTokenVencido(isVencido);

//         if (isVencido) {
//           reject('token_vencido');
//           // return;
//         }
//         resolve(true);
//       }
//     });

//   const refreshToken = () =>
//     new Promise<boolean>(async (resolve, reject) => {
//       const _token = await currentToken().catch((error) => {
//         console.log('[ interceptor ] - refreshToken error:', error);
//         reject('no_token');
//       });
//       const _sessionStatus = authService.getSessionStatus();
//       console.log('[ interceptor ] - refresh token:', _token, _sessionStatus);

//       if (
//         _token &&
//         _token.refreshToken &&
//         _sessionStatus &&
//         _sessionStatus === 'ativa'
//       ) {
//         console.log('[ interceptor ] - refreshing token...');

//         const newToken = await authService
//           .requestRefreshAccessToken(_token.refreshToken)
//           .catch((error: IApiResponse) => {
//             console.log('[ interceptor ] - refreshing token -> ERRO:', error);
//             // reject('no_token);
//             reject(error);
//           });

//         if (newToken) {
//           newToken.tokenStatus = 'vigente';

//           authService.setTokenToLocalHistory(newToken);
//         }
//       } else {
//         resolve(false);
//       }
//     });

//   let tokenData: accessTokenDataModel | undefined;
//   currentToken().then(async (token) => {
//     console.log('[ interceptor ] - tem token? (dentro)', token);

//     if (token && token.accessToken) {
//       try {
//         let _resp: boolean | undefined = undefined;

//         const _result = await checkToken();

//         if (_result) {
//           let _requestWithToken = req.clone({
//             setHeaders: {
//               Authorization: `Bearer ${token.accessToken}`,
//             },
//           });
//           console.log('interceptor - request com token:', _requestWithToken);

//           return next(_requestWithToken);
//         }
//         // return next(req);
//       } catch (error) {
//         console.log('interceptor - request com token -> ERRO:', error);
//       }
//     }
//     return next(req);
//   });

//   console.log('[ interceptor ] - tem token? (fora)', tokenData);
//   // return next(req);
// };

// export const CACHING_ENABLED = new HttpContextToken<boolean>(() => true);

// export function tokenInterceptor(
//   req: HttpRequest<unknown>,
//   next: HttpHandlerFn
// ): Observable<HttpEvent<unknown>> {

//   if (req.context.get(CACHING_ENABLED)) {
//     // apply caching logic

//     // inject(LocalStorageService).setSessionItem(
//     //   environment.LOCAL_STORAGE_TOKEN_KEY,
//     //   req.body
//     // );
//     // console.log('cachingInterceptor -> token:', req.body);

//     return next(req).pipe(
//       tap((event) => {
//         if (event.type === HttpEventType.Response) {
//           console.log(
//             req.url,
//             'loggingInterceptor CACHING_ENABLED -> returned a response with status',
//             event.status
//           );
//         }

//         const token = inject(LocalStorageService).getSessionItem(
//           environment.LOCAL_STORAGE_TOKEN_KEY
//         );
//         console.log('loggingInterceptor CACHING_ENABLED -> token:', token);

//         if (token) {
//           const _token = JSON.parse(token) as accessTokenDataModel;
//           console.log('loggingInterceptor CACHING_ENABLED -> token:', _token);

//           const _requestWithToken = req.clone({
//             setHeaders: {
//               Authorization: `Bearer ${_token.accessToken}`,
//             },
//           });

//           return next(_requestWithToken);
//         } else {
//           return next(req);
//         }
//       })
//     );
//   } else {
//     // caching has been disabled for this request
//     return next(req);
//   }
// }

// const tokenSignal = signal<accessTokenDataModel | undefined>(undefined);

export function tokenInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  // Inject the current `AuthService` and use it to get an authentication token:
// debugger;

  const token = inject(AuthenticationService).getTokenFomLocalHistory();
  // console.log('tokenInterceptor -> token:', token);

  if(token && token?.accessToken) {
    // console.log('tokenInterceptor -> accessToken:', token.accessToken);
        
    // let newReq = req.clone({
    //   headers: req.headers.append(
    //     'Authentication',
    //     _autenticacao
    //   ),
    // });
    let newReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    });
    // console.log('tokenInterceptor -> _requestWithToken:', newReq);
  
    // Clone the request to add the authentication header.
    // const newReq = req.clone({
    //   headers: req.headers.append('X-Authentication-Token', authToken),
    // });
    return next(newReq);
  }

  return next(req);
}


// export function tokenInterceptor2(
//   req: HttpRequest<unknown>,
//   next: HttpHandlerFn
// ): Observable<HttpEvent<unknown>> {
//   console.log('loggingInterceptor -> request URL:', req.url);

//   try {

//     // if (req.context.get(CACHING_ENABLED)) {
//     // }
//     const token = inject(LocalStorageService).getSessionItem(
//       environment.LOCAL_STORAGE_TOKEN_KEY
//     );
//     console.log('loggingInterceptor -> token:', token);
  
//     if (token) tokenSignal.set(JSON.parse(token) as accessTokenDataModel);
  
//     return next(req).pipe(
//       tap((event) => {
//         if (event.type === HttpEventType.Response) {
//           console.log(
//             req.url,
//             'loggingInterceptor -> returned a response with status',
//             event.status
//           );
//         }
  
//         console.log('loggingInterceptor -> token:', tokenSignal()?.accessToken);
  
//         if (tokenSignal()?.accessToken) {
//           // const _token = JSON.parse(token) as accessTokenDataModel;
//           const _autenticaca0 = `Bearer ${tokenSignal()!.accessToken}`;
  
//           let newReq = req.clone({
//             headers: req.headers.append(
//               'X-Authentication-Token',
//               _autenticaca0
//             ),
//           });
//           console.log('loggingInterceptor -> _requestWithToken:', newReq);
  
//           return next(newReq);
  
//           // const _requestWithToken = req.clone({
//           //   setHeaders: {
//           //     Authorization: `Bearer ${_token.accessToken}`,
//           //   },
//           // });
//           // console.log(
//           //   'loggingInterceptor -> _requestWithToken:',
//           //   _requestWithToken
//           // );
  
//           // return next(_requestWithToken);
//         } else {
//           return next(req);
//         }
//       })
//     );
//   } catch (error) {
//     console.log('loggingInterceptor -> request -> ERRO:', error);
//     return next(req);
//   }
// }
