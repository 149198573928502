export class SideNavDataModel {
  order?: number;
  routerLink?: string;
  icon?: string;
  label?: string;
  expanded?: boolean;
  is_admin_only?: boolean;
  items?: SideNavDataModel[];
}

export interface ISideNavToggle {
  screenWidth: number;
  collapsed: boolean;
  mobile: boolean;
}

export interface ISideNavData {
  order?: number;
  routerLink?: string;
  icon?: string;
  label?: string;
  expanded?: boolean;
  is_admin_only?: boolean;
  items?: ISideNavData[];
}
