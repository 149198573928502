import { CanActivateFn } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { inject } from '@angular/core';


export const authGuard: CanActivateFn = (route, state) => {

  const authorization = inject(AuthorizationService);

  console.log('authGuard - route - state:', route, state);

  const menu = route.url.length > 0 ? route.url[0].path : undefined;

  console.log('authGuard - menu atual:', menu, 'Autorizado:', authorization.isAuthorized);

  return authorization.isAuthorized;
};
