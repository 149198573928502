import { Component, Input, OnInit } from '@angular/core';
import { ISideNavData, SideNavDataModel } from '../../models/SideNav.data.model';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { fadeInOut, submenuTrigger } from '../animations/sidenav.animations';
import { AuthorizationService } from '../../services/authorization.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'sublevel-menu',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  template: `
<ul
  [@submenu]="expanded
    ? {value: 'visible', params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', height: '*'}}
    : {value: 'hidden', params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', height: '0'}} "
  *ngIf="collapsed && data.items && data.items.length">
  <li *ngFor="let item of data.items">
    <ng-container *ngIf="getItemVisibility(item)">
      <a *ngIf="item.items && item.items.length > 0" (click)="handleClick(item)"
      [ngClass]="!item.expanded ? 'text-topvix-primary': 'text-topvix-button hover:bg-transparent' "
        class="flex items-center h-12 rounded text-topvix-button transition-colors duration-75 ease-linear">
        <span class="{{item.icon||'icon-[carbon--dot-mark]'}} duration-75 hover:translate-x-2"></span>
        <span *ngIf="collapsed">{{item.label}}</span>
        <span
          *ngIf="item.items && collapsed"
          [ngClass]="!item.expanded ? 'icon-[carbon--chevron-right]': 'icon-[carbon--chevron-down]' "></span>
      </a>
      <a
        class="flex flex-row items-center justify-evenly h-10 ml-4 rounded text-topvix-secondary hover:bg-topvix-button hover:text-topvix-bg transition-colors duration-75 ease-linear"
        *ngIf="!item.items || (item.items && item.items.length === 0)"
        [routerLink]="[item.routerLink]"
        [routerLinkActiveOptions]="{exact: true}"
        routerLinkActive="bg-topvix-secondary text-white">
          <span class="{{item.icon||'icon-[carbon--dot-mark]'}} w-12 min-w-12 text-xl text-center duration-75 hover:translate-x-2"></span>
          <span class="flex-grow ml-2 text-sm" *ngIf="collapsed" @fadeInOut>{{item.label}}</span>
      </a>
        <div *ngIf="item.items && item.items.length && getItemVisibility(item)">
          <sublevel-menu
            [data]="item"
            [collapsed]="collapsed"
            [expanded]="item.expanded"
            [multiple]="multiple"></sublevel-menu>
        </div>
    </ng-container>
  </li>
  <ul>
  `,
  styles: ``,
  animations: [submenuTrigger, fadeInOut]
})
export class SublevelMenuComponent implements OnInit {
  @Input() data = new SideNavDataModel();
  @Input() collapsed = false;
  @Input() animating?: boolean;
  @Input() expanded?: boolean;
  @Input() multiple = false;

  constructor(
    private readonly authorizationService: AuthorizationService,
    public readonly userService: UserService,
  ) { }

  ngOnInit(): void {

  }

  handleClick(item: ISideNavData) {
    if (!this.multiple) {
      if (this.data.items && this.data.items.length) {
        for (let modelItem of this.data.items) {
          if (item !== modelItem && modelItem.expanded) {
            modelItem.expanded = false;
          }
        }
      }
    }
    item.expanded = !item.expanded;
  }

  getItemVisibility(data: ISideNavData) {

    // console.log('[ SideNav ] getItemVisibility ->', 'to Admin Only?', data.is_admin_only, 'Perfil Geral:', this.authorizationService.currentUserProfile.user_account_role, 'Perfil Empresa:', this.authorizationService.currentUserProfile.User?.user_role);

    let _isToShow = true;

    if (data.is_admin_only && this.userService.currentUser.user_role !== 'ADMIN') {
      _isToShow = false;
    }

    return _isToShow;
  }
}
