import { ChangeDetectorRef, Component, inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '../../components/footer/footer.component';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { IdleModules } from '../../idle.module';
import { IdleService } from '../../services/idle.service';
import { environment } from '../../../environments/environment';
import { DrawerService } from '../../services/drawer.service';
import { CommonModule } from '@angular/common';
import { WebsocketService } from '../../services/websocket.service';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [BreadcrumbComponent, RouterOutlet, FooterComponent, IdleModules, CommonModule],
  providers: [Idle],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit, OnDestroy {
  @Input() collapsed = false;
  @Input() screenWidth = 0;

  title = 'New Topvix';

  router = inject(Router);


  

  constructor(
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private idleService: IdleService,
    private readonly drawerService: DrawerService,
    private wsSocket: WebsocketService,
  ) {

    //
    //para evitar o erro: https://angular.dev/errors/NG0506
    //
    const ngZone = inject(NgZone);
    ngZone.runOutsideAngular(() => {
      
      // *** VERIFICADOR DE TEMPO DE INATIVIDADE DO USUÁRIO (tempo em segundos)
      idle.setIdle(environment.IDLE_TIMEOUT);
      idle.setTimeout(environment.LOGIN_TIMEOUT);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  
      idle.onIdleStart.subscribe(() => {
        console.log('Idle Monitoring -> ** NO Activity **');
        this.idleService.setIdleStatus('NO ACTIVITY');
        this.drawerService.closeDrawer();
        // this.drawerService.closeSidebar();
        // this.drawerService.closeMobileSidebar();
        cd.detectChanges();
      });
  
      idle.onIdleEnd.subscribe(() => {
        console.log('Idle Monitoring -> In Activity');
        this.idleService.setIdleStatus('IN ACTIVITY');
      });
  
      idle.onTimeout.subscribe(() => {
        console.log('Idle onTimeout -> Logout');
        this.idleService.setIdleStatus('TIMEOUT');
  
        this.drawerService.closeDrawer();
        // this.drawerService.closeSidebar();
        // this.drawerService.closeMobileSidebar();
  
        idle.stop();
  
        this.router.navigateByUrl('/expired'); //com informativo da sessão expirada por inatividade
        // this.router.navigateByUrl('/login'); //direto pro login
      });
      // ****
    });
    
  }

  startIdleMonitoring() {
    console.log('Idle Monitoring -> Start Watching');
    this.idle.watch();
  }

  stopIdleMonitoring() {
    console.log('Idle Monitoring -> Stop Watching');
    this.idle.stop();
  }

  ngOnInit(): void {

    if(this.idleService.idleCurrentCheckingStatus) {
      this.startIdleMonitoring();
    }

    this.idleService.idleCheckingStatusObserver().subscribe(value => {

      const _running = this.idle.isRunning();

      if(!_running && value) {
        this.startIdleMonitoring();
      }

      if(_running && !value) {
        this.stopIdleMonitoring();
      }

      console.log('[ Home ] checking idle status:', _running, ' active?', value);

    });
  }

  ngOnDestroy(): void {
    this.wsSocket.disconnect();
    this.stopIdleMonitoring();
  }


  getBodyClass() {
    const _class = "h-full ml-1 z-0 relative top-0 transition-all duration-150 ease-linear";

    if (this.collapsed)
      return `w-[calc(100vw - 16.5625rem)] ${_class}`;

    return `w-[calc(100vw - 15rem)] ${_class}`;
  }

}
