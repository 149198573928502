import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import {
  SideNavDataModel,
  ISideNavToggle,
  ISideNavData,
} from '../../models/SideNav.data.model';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { SublevelMenuComponent } from './sublevel-menu.component';
import { fadeInOut, rotateButton } from '../animations/sidenav.animations';
import { AuthorizationService } from '../../services/authorization.service';
import { UserService } from '../../services/user.service';
import { ModalDialogStatusService } from '../../services/modal-dialog-status.service';
import { eLoggedType, popupStatus } from '../../enums/Enums';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'side-nav',
  standalone: true,
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.css',
  animations: [fadeInOut, rotateButton],
  imports: [CommonModule, RouterLink, RouterLinkActive, SublevelMenuComponent],
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Input() set modalStatus(value: popupStatus) {
    this.currentModalDeleteConfirmationStatus = value;
  }
  @Input() collapsar = false;
  @Output() onToggleSideNav: EventEmitter<ISideNavToggle> = new EventEmitter();

  routes = inject(Router);

  menuOptionsSubscription?: Subscription;
  deleteStatusSubcription?: Subscription;
  loggedTypeSubscription?: Subscription;

  currentLoggedType?: eLoggedType;

  constructor(
    private readonly authorizationService: AuthorizationService,
    private readonly authService: AuthenticationService,
    public readonly userService: UserService,
    public readonly cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,    
  ) {
  }
  
  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  
  currentModalDeleteConfirmationStatus?: popupStatus;


  collapsed = false;
  pinned = false;
  screenWidth = 0;
  multiple = false;
  mobile = false;

  navData: SideNavDataModel[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.isBrowser) {
      this.screenWidth = window.innerWidth;

      if (this.screenWidth <= 768) {
        this.collapsed = false;
        this.pinned = false;
        this.onToggleSideNav.emit({
          collapsed: this.collapsed,
          screenWidth: this.screenWidth,
          mobile: true,
        });
      }
      // console.log('[ SideNav ] screen:', this.screenWidth);
    }
  }

  ngOnInit() {

    if (this.isBrowser) {
      this.screenWidth = window.innerWidth;

      this.onToggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
        mobile: this.screenWidth <= 768,
      });

      this.menuOptionsSubscription = this.authorizationService
        .transactionMenuSideNavObserver()
        .subscribe((value) => {
          if (value) {
            this.navData = value.sort((a, b) => a.order! - b.order!);
          }
        });

      if (this.navData && !this.navData.length) {
        this.loadMenuOption();
      }

      this.loggedTypeSubscription = this.authorizationService
        .currentLoggedTypeObserver()
        .subscribe((value) => {
          this.currentLoggedType = value;
        });
    }
  }

  ngOnDestroy(): void {
    if (this.deleteStatusSubcription)
      this.deleteStatusSubcription.unsubscribe();
    if (this.menuOptionsSubscription)
      this.menuOptionsSubscription.unsubscribe();
    if (this.loggedTypeSubscription) this.loggedTypeSubscription.unsubscribe();
  }


  async loadMenuOption() {
    // debugger;

    const _loggedIn = this.authService.isAuthenticated;

    if (!_loggedIn) return;

    const opcoes =
      await this.authorizationService.getTransactionsMenuRouteList();

    if (opcoes && opcoes.length) {
      this.navData = opcoes;
      // this.authorizationService.setCurrentTransactionMenuSideNavOptions(
      //   menuOption
      // );
    }
  }

  toggleCollapsed() {
    if (this.pinned) return;

    this.collapsed = !this.collapsed;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
      mobile: this.screenWidth <= 768,
    });
  }

  pinSideNav() {
    this.pinned = !this.pinned;
  }

  handleClick(item: ISideNavData) {
    this.shrinkItems(item);
    item.expanded = !item.expanded;
  }

  getActiveClass(data: ISideNavData) {
    // console.log('[ SideNav ] getActiveClass ->', data.routerLink, decodeURI(this.routes.url));

    if (data.routerLink) {
      const _url = decodeURI(this.routes.url);
      return _url.includes(data.routerLink)
        ? `bg-topvix-secondary text-white`
        : '';
    }
    return '';
  }

  getItemVisibility(data: ISideNavData) {
    // console.log('[ SideNav ] getItemVisibility ->', 'to Admin Only?', data.is_admin_only, 'Perfil Geral:', this.authorizationService.currentUserProfile.user_account_role, 'Perfil Empresa:', this.authorizationService.currentUserProfile.User?.user_role);

    let _isToShow = true;

    if (
      data.is_admin_only &&
      this.userService.currentUser.user_role !== 'ADMIN'
    ) {
      _isToShow = false;
    }

    return _isToShow;
  }

  shrinkItems(item: ISideNavData) {
    if (!this.multiple) {
      for (let modelItem of this.navData) {
        if (item !== modelItem && modelItem.expanded) {
          modelItem.expanded = false;
        }
      }
    }
  }
}
