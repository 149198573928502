<nav id="breadcrumb"
  class="{{isHidden?'hidden':''}} flex m-1 px-5 py-3 w-auto text-gray-900 border rounded-lg {{idleBGColor}} shadow"
  aria-label="Breadcrumb">
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">

    <li class="inline-flex items-center">
      <div
        class="inline-flex items-center text-sm font-medium hover:text-blue-600">
        <span class="icon-[mdi--home] text-2xl"></span>
      </div>
    </li>

    <li *ngFor="let page of pages">
      <div *ngIf="page" class="flex items-center">
        <span class="icon-[mdi--navigate-next] text-2xl text-gray-400"></span>
        <div
          class="ms-1 text-sm font-medium hover:text-blue-600 md:ms-2">{{
          page | pageAlias }}
        </div>
      </div>
    </li>
  </ol>
</nav>
